body {
  font-family: Arial, sans-serif;
}
.container {
  width: 80%;
  margin: 0 auto;
}
h1 {
  text-align: center;
  margin-top: 20px;
}
.note-form {
  margin-top: 20px;
}
.note-form input[type="text"],
.note-form textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
}
.note-form input[type="submit"] {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}
.note-list {
  margin-top: 20px;
}
.note {
  background-color: #f2f2f2;
  padding: 10px;
  margin-bottom: 10px;
}
.note-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 5px;
}
.note-actions button {
  margin-left: 5px;
  background-color: #f44336;
  color: white;
  padding: 5px 10px;
  border: none;
  cursor: pointer;
}
